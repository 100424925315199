const MESSAGE_VARIANT = {
  SUCCESS: "SUCCESS",
  ERROR: "ERROR",
  WARNING: "WARNING"
};

const TAB = {
  ENERGY: "Energy",
  ABOUT: "About"
}

const TRANSACTION_TYPE = {
  CRYPTO: "Crypto",
  BANK: "Bank",
  SUNSET_WALLET: "Sunset Wallet"
}

export {
  MESSAGE_VARIANT,
  TAB,
  TRANSACTION_TYPE,
}