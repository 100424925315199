/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable prefer-template */
/* eslint-disable camelcase */
import "../../../assets/css/header.css";
import React from "react";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import sunsetCapitalLogo from "../../../assets/images/sunset_capital_logo.png";
import MobileSideBar from "./mobile-side-bar";
import Profile from "../../../app/pages/profile";

const Header = () => {
  const { isLoggedIn } = useSelector((state) => state.home);

  const openNav = () => {
    document.getElementById("mySidenav").style.width = "250px";
  };

  const closeNav = () => {
    document.getElementById("mySidenav").style.width = "0";
  };

  return (
    <div className="header-main">
      <nav className="navbar navbar-expand-md navbar-light bg-white px-lg-4 px-md-1">
        <div className="container-fluid" style={{ justifyContent: "center" }}>
          <button
            className="navbar-toggler ms-3 private"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={openNav}
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <NavLink className="navbar-brand" activeclassname="active" to="/">
            <img className="img-fluid" src={sunsetCapitalLogo} alt="" />
          </NavLink>

          {isLoggedIn && <Profile />}
        </div>
      </nav>
      <div id="mySidenav" className="sidenav">
        <a href="#" className="closebtn" onClick={closeNav}>
          &times;
        </a>
        <MobileSideBar closeNav={closeNav} />
      </div>
    </div>
  );
};

export default React.memo(Header);
