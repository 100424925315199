/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect } from 'react'
import { useSelector } from "react-redux";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

const documentStyle = {
  ':after': {
    right: "0"
  }
}

const DashboardDocument = () => {
  const { selectedCrypto, masterData } = useSelector((state) => state.nft);

  const tokenDetail = masterData?.crypto_tokens?.filter(ele => ele?.id === selectedCrypto)[0];
  const [financialDocument, setFinancialDocument] = useState(tokenDetail?.financial_documents ? tokenDetail?.financial_documents : []);
  const [businessDocument, setBusinessDocument] = useState(tokenDetail?.business_documents ? tokenDetail?.business_documents : []);

  useEffect(() => {
    const tokenDetail = masterData?.crypto_tokens?.filter(ele => ele?.id === selectedCrypto)[0];
    setFinancialDocument(tokenDetail?.financial_documents ? tokenDetail?.financial_documents : []);
    setBusinessDocument(tokenDetail?.business_documents ? tokenDetail?.business_documents : []);
  }, [selectedCrypto])

  return (
    <div className="document-style mb-4 mb-lg-5" style={documentStyle}>
      <ul>
        <li className='d-flex position-unset'>Financial Document:
          {
            financialDocument?.length ?
              financialDocument.map((ele, index) => (
                <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip-2">{ele?.name}</Tooltip>} key={index}>
                  <a className="document-btn" href={ele?.document} target='blank'>
                    <i className="fa fa-file" aria-hidden="true" />
                  </a>
                </OverlayTrigger>
              ))
              :
              <></>
          }
        </li>
        <li className='d-flex position-unset'>Business Document :
          {
            businessDocument?.length ?
              businessDocument.map((ele, index) => (
                <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip-2">{ele?.name}</Tooltip>} key={index}>
                  <a className="document-btn" href={ele?.document} target='blank'>
                    <i className="fa fa-file" aria-hidden="true" />
                  </a>
                </OverlayTrigger>
              ))
              :
              <></>
          }
        </li>
      </ul>
    </div>
  )
}

export default DashboardDocument