import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import DashboardBanner from "../../dashboard/components/dashboardBanner";
import DashboardHeading from "../../dashboard/components/dashboardHeading";
import DashboardSummary from "../../dashboard/components/dashboardSummery";
import SmartContractModal from "../../../../components/modal/smartContractModal";
import NFTFormCrypto from "./nftFormCrypto";

const NFTTabDashboard = () => {
  const { userDetail } = useSelector((state) => state.home);
  const { selectedCrypto, masterData } = useSelector((state) => state.nft);

  const [isDashboardLoading, setIsDashboardLoading] = useState(false);

  const exchangeData = masterData?.exchange_data?.filter(ele => ele?.crypto_token_id === selectedCrypto)[0];
  let message = {
    cryptoPercent: exchangeData?.tsp_discount_percentage,
    cryptoMessage: exchangeData?.tsp_discount_message,
  };

  const value = useRef(null);
  value.current = {
    walletAddress: userDetail?.wallet_address,
    exchangeData: masterData?.exchange_data?.filter(ele => ele?.crypto_token_id === selectedCrypto)[0],
    token: masterData?.crypto_tokens?.filter(ele => ele?.id === selectedCrypto)[0],
    currency: masterData?.exchange_data[0].supported_fiat_currencies,
    currencyToken: masterData?.crypto_types,
  };

  return (
    <div className="card border-0">
      <div className="tab-content" id="myTabContent">
        <div className="tab-pane fade show active" id="energy-nft-pane" role="tabpanel" aria-labelledby="home-tab" tabIndex="0">
          <DashboardBanner />
          <div className="service-content">
            <DashboardHeading tabSwitcher="no" />
            <DashboardSummary isDashboardLoading={isDashboardLoading} setIsDashboardLoading={setIsDashboardLoading} />
            <SmartContractModal />

            <div className="row">
              {value.current.token !== undefined && (
                <NFTFormCrypto data={value.current} ddshow={true} />
              )}

              <div className="col-12 col-lg-6">
                <div className="result-box text-center">
                  <h2 className="text-white">
                    {
                      message.cryptoPercent ?
                        <span>{message.cryptoPercent}% Discount for a limited period.</span>
                        :
                        <span>No discount available.</span>
                    }
                  </h2>
                  <div className="form-discount" dangerouslySetInnerHTML={{ __html: message.cryptoMessage }} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NFTTabDashboard;
